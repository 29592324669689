import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component:  () => import('../views/index/index.vue')
  },
  // {
  //   path: '/articleList',
  //   name: 'articleList',
  //   component: () => import('../views/articleList/articleList.vue')
  // },
  // {
  //   path: '/quizList',
  //   name: 'quizList',
  //   component: () => import('../views/quizList/quizList.vue')
  // },
  {
    path: '/goGroup',
    name: 'goGroup',
    component: () => import('../views/goGroup/goGroup.vue')
  },
  // {
  //   path: '/talk',
  //   name: 'talk',
  //   component: () => import('../views/talk/talk.vue')
  // },
  {
    path: '/history',
    name: 'history',
    component: () => import('../views/history/history.vue')
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/home/home.vue')
  },
  {
    path: '/vip',
    name: 'vip',
    component: () => import('../views/vip/vip.vue')
  },
  {
    path: '/orderDetail',
    name: 'orderDetail',
    component: () => import('../views/orderDetail/orderDetail.vue')
  },
  {
    path: '/refund',
    name: 'refund',
    component: () => import('../views/refund/refund.vue')
  },
  {
    path: '/refundStatus',
    name: 'refundStatus',
    component: () => import('../views/refundStatus/refundStatus.vue')
  },
  {
    path: '/test',
    name: 'test',
    component: () => import('../views/test/test.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
export default router
