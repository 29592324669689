<template>
  <div class="cyContainer clearfix">
    <!-- loading -->
    <initloading v-if="showLoading"></initloading>   
    
    <div class="box-1" id="list-box" style="">
			<div class="talk-list">
        
				<div v-for="(item,index) in talkList" :key="index" :id="`msg-${item.time}-${item.type}`" >
					<div class="item flex_col" :class="item.type == 1 ? 'push':'pull'">
            <!-- 头像 -->
						<img v-if="item.type==1" :src="(userInfo&&userInfo.avatar)||item.pic" class="pic"/>
						<img v-else :src="item.pic" class="pic"/>

            <div v-if="item.loading" class="content" style="height:50px;display:flex;align-items:center">
              <loadingdot></loadingdot>
            </div>
            <template v-else>
              <div v-if="item.quiz_title" class="content contentText" style="text-align:justify">
                <div class="quiz_title">{{item.quiz_title}}</div>
                <div class="quiz_list" style="margin-top:10px">
                  <div class="quiz_item" hover-class="quiz_item_hover" v-for="(quiz,i) in item.quiz_list" :key="i" @click="bindQuizClick(quiz)">
                    <span class="quiz_txt" style="display:inline-block;max-width:90%;">{{i+1}}. {{quiz.question}}</span>
                    <img class="quiz_icon" src="@/assets/arrow.png" />
                  </div>
                  <div class="quiz_item" style="margin-top:10px;padding-bottom:0">
                    <span>请开始输入聊天内容，让我陪你聊天吧~</span>
                  </div>
                </div>
              </div>
              <div v-else class="content contentText" style="white-space: pre-wrap;">
                <span>{{item.content}}</span>
                <span v-if="text&&item.type!=1&&!item.code&&item.isCurrent" class="flash">|</span>
              </div>
            </template>
           
					</div>
          <div v-if="item.type != 1&&item.time" class="talkHandle">
            <template v-if="item.code&&!item.isCurrent">
              <div class="btn talkCopy" @click="copyClick(item.content)">
                <img class="talkIcon" src="@/assets/copy.png" />
                复制回答
              </div>
              <div class="btn talkShare" @click="bindTalkShare(item,index)">
                <img class="talkIcon" src="@/assets/share.png" />
                分享会话
              </div>
            </template>
          </div>

				</div>
			</div>
		</div>

    <!-- 输入框 -->
    <div class="box-2">
      <cyinput v-if="cyInputShow" @bindhistory="bindHistory" :getphone="isGetPhone" :usernum="userNum" :disabled="isFlag" :path="currentPath" @bindsubmit="send" @empower="bindEmpower"></cyinput>
		</div>
    <!-- 分享引导 -->
    <shareyindao :show="showYindaoShare" @bindclose="bindYindaoShareOut"></shareyindao>

    <!-- 提示 -->
    <show-toast :show='showToast.show' :title="showToast.title" @close="showToastClose"></show-toast>
  </div>
</template>

<script>
  import config from '../../../config.js';
  import util from '../../../utils/utils';
  import request from '../../../api/request';
  

  import initloading from '@/components/initLoading/index.vue';
  import cyinput from '@/components/input';
  import loadingdot from '@/components/loadingDot';
  import shareyindao from "@/components/shareYindao";
  import showToast from "@/components/showToast";

  export default {
    components:{
      initloading,
      cyinput,
      loadingdot,
      shareyindao,
      showToast
    },
    data(){
      return{
        cyInputShow:true,
				talkList:[],
        talkData:{},
				ajax:{
					rows:10,	//每页数量
					page:0,	//页码
					flag:true,	// 请求开关
					loading:true,	// 加载中
					loadText:'正在获取消息'
				},
        talkSharePath:"",//会话分享路径
        navBackBtn:'',//返回按钮
        navigatorHeight:'',//顶部导航栏高度
				content:'',//输入框内的数据
				openid:'',
        navbarTitle:config.appletName,
        showLoading:true,//全屏loading
        talk:'',//传送过来的会话
        text:'',//webSocket返回的临时会话
        webSocketNum:0,//webSocket返回次数

        subscribeConfig:{}, //订阅消息
        isGetPhone:false, //是否显示授权手机号按钮
        phoneNum:10, //第十次获取手机号
        userNum:3,//第三次获取用户信息
        isFlag:false,//是否可继续发送状态
        talkShare:{},
        robot_avatar:require('@/assets/touxiang.png'),
        touxiang:require('@/assets/'+config.touxiang),
        avatar:'',
        showYindaoShare:false,
        userInfo:'',
        scrollTop:0,
        showToast:{
          show:false,
          title:"",
        },

        websocket:null
      }
    },
    created(){
      const self=this;
      let options=util.getPathOptions();
      console.log("options:",options);
      //如果是其他页面分享进入，进行跳转
      if(options&&options.target){

        // 如果是对话详情页并且是伪静态
        if(options.target=='talk'&&config.isStatic){
          location.href=config.host+'/talk/'+item.code+'.html?talkInfo='+options.talkInfo;
          return
        }else{
          let url=options.target+".html";
          if(options.setFrom){
            // delete options.target
            url+='?'+util.optionsFormat(options);
          }
          location.href=url
        }

      }

      try{
        //兼容之前传递参数
        if(options.talk){
          let options_talk=decodeURIComponent(options.talk);
          this.talk=JSON.parse(options_talk);
          console.log("options_talk:",this.talk);
        }
        //新版传递参数
        if(options.talkInfo){
          //如果传递参数放到了缓存里
          if(options.talkInfo=="storage"){
            this.talk=JSON.parse(localStorage.getItem("talk_options"));
            localStorage.removeItem('talk_options')
          }else{
            this.talk=JSON.parse(options.talkInfo);
          }
          console.log("options_talk:",this.talk);
        }

      } catch(err){console.error(err)}
			if(options.setFrom&&!options.target){
				// util.setStatistics(options.setFrom,'chat');
				util.setStatistics(options,'chat');
			}
			 
      let userInfo=localStorage.getItem('userInfo')||"{}";
      userInfo=JSON.parse(userInfo);

      if(userInfo&&userInfo.avatar){
        this.robot_avatar=userInfo.avatar;
        this.userInfo=userInfo;
      }
      document.title=config.appletName

      // 获取用户信息及openid
      if(options.code){
        console.log("获取到授权code：",options.code);
        util.getUserInfo(options.code).then(res=>{
          console.log("userInfo:",res);
          if(res&&res.avatar){
            localStorage.setItem('userInfo',JSON.stringify(res))
            this.userInfo=res;

            let sendChatContent=localStorage.getItem('sendChatContent');
            if(sendChatContent){
              self.send(sendChatContent);
              localStorage.removeItem('sendChatContent')
            }
          }
        }).catch(err=>{
        this.$message({
            showClose: true,
            message: '登录失败！请稍后再试',
            duration:2000
          });
      })
        delete options.code
        delete options.state
      }
      this.currentPath=util.getPath();
      this.options=options;
    },
    mounted() {
			const self = this;
			this.$nextTick(()=>{
				this.init();
			});
      if(util.isWechat()){
        try{
          let shareInfo=this.bindShareAppMessage();
          this.$wx.getConfig(shareInfo.title, "越早打开越好", shareInfo.path, shareInfo.imageUrl);
        } catch(err){}
      }

      let el=document.querySelector('.cyContainer');
      document.documentElement.style.fontSize = el.offsetWidth / 750 * 100 + 'px';
      
		},
    methods:{
      async init(){
        const self=this;
        if(this.talk){
          this.talkList=[
            {
              "pic":this.robot_avatar,
              "content":this.talk.me,
              "time":this.talk.time,
              "type":1
            },
            {
              "pic":this.touxiang,
              "content":this.talk.robot,
              "time":this.talk.time,
              "code":this.talk.code,
              "type":0,
              "fromType":'share',
              "questioner_id":this.talk.questioner_id
            }
          ]
        }else{
          await this.getQuiz();

          this.$nextTick(()=>{
            // window.scrollTo(0, 999999)
          })
        }
        setTimeout(()=>{
          this.showLoading=false;
        },500)
        
        //获取手机号-第十次
        // setTimeout(()=>{
        //   let sendNum=localStorage.getItem('sendNum')||1;
        //   request.getPhoneStart().then(res=>{
        //     if(res.data.code==1){
        //       self.isGetPhone=false;
        //       getApp().globalData.isPhone=true;
        //     }else{
        //       getApp().globalData.isPhone=false;
        //       if(sendNum==this.phoneNum){
        //         this.isGetPhone=true;
        //       }
        //     }
        //   }).catch(err=>{})
        // },1200)
        
      },
      // 获取提问数据
      getQuiz(){
        const self=this;
        let hail=util._formatTimeText();//获取上午好下午好问候语
        request.getQuizData().then(res=>{
          if(res.data){
            this.talkList=[{
              type:0,
              pic:this.touxiang,
              quiz_title:hail+"，我是"+config.appletName+"，您可以这样问我：",
              quiz_list:res.data
            }]
          }else{
            throw "获取问题失败";
          }
        }).catch(err=>{
          this.talkList=[{
            pic:this.touxiang,
            "content":hail+"，我是"+config.appletName+"，您可以问我任何问题，也可以让我写任何文章，比如你可以输入“写一篇"+hail+"的祝福文章”",
            "type":0
          }]
        })
      },
      //问题点击
      bindQuizClick(quiz){
        const self=this;
        //正在请求
        if(this.isFlag){
          self.showToast={
            show:true,
            title: '正在处理中...请稍后！',
          }
          return 
        }
        self.talkList.push({
            "content":quiz.question,
            "type":1,
            "pic":this.robot_avatar
          },{
            "pic":this.touxiang,
            "loading":true,
            "content":"正在处理...",
            "type":0
        });
        self.$nextTick(()=>{
          window.scrollTo(0, 999999)
        })
        setTimeout(()=>{
          self.talkList.splice(self.talkList.length-1,1);
          self.talkList.push({
            "pic":self.touxiang,
            "content": quiz.content,
            "time":new Date().getTime(),
            "type":0,
            "code":quiz.code,
            'fromType':'quizChat'
          })
          self.$nextTick(()=>{
            self.scrollTop=999999
            // window.scrollTo(0, 999999)
          })
        },800)

      },
      // 隐藏加载提示
			hideLoadTips(flag){
				if(flag){
					this.ajax.loadText = '消息获取成功';
					setTimeout(()=>{
						this.ajax.loading = false;
					},300);
				}else{
					this.ajax.loading = true;
					this.ajax.loadText = '正在获取消息';
				}
			},
      // 消息格式处理
      contentFormat(content){
        if(content instanceof Array&&content.length==1){
          content=content[0];
        }else if(typeof(content)!='string') {
          content=JSON.stringify(content);
        }
        return content.trim();
      },

      // 发送信息
			send(content){
        console.log(content);
        
        const self=this;
				if(!content){
          self.showToast={
            show:true,
            title: '请输入有效的内容',
          }
					return;
				}
				
        if(this.isFlag){
          self.showToast={
            show:true,
            title: '正在处理中...请稍后！',
          }
          return 
        }

        try{
          let sendNum=localStorage.getItem('sendNum')||1;
            sendNum++;
            localStorage.setItem('sendNum', sendNum);
            continue_request();
        } catch(err){
          console.error(err)
          continue_request();
        }

        function continue_request(){

          
          //初始input
          self.cyInputShow=false;
          self.$nextTick(()=>{
            self.cyInputShow=true;
          })

          self.isFlag=true;

          self.content=content;
          // 将当前发送信息 添加到消息列表。
          self.talkList.push({
              "content":self.content.trim(),
              "type":1,
              "pic":self.robot_avatar
            },{
              "pic":self.touxiang,
              "loading":true,
              "content":"正在处理...",
              "type":0
          });
          
          //延时滑动到底部-避免安卓部分手机不生效问题
          setTimeout(()=>{
            window.scrollTo(0, 999999)
          },300)


          // 修复发送消息后，字体大小变化问题
          try{
              let s=0;
              let timer=setInterval(()=>{
                let el=document.querySelector('.cyContainer');
                document.documentElement.style.fontSize = el.offsetWidth / 750 * 100 + 'px';
                if(s>200){
                  s=0
                  clearInterval(timer)
                }
                s+=10
            },10)
          } catch(err){}
          
          //////////////////////////////建立websocket/////////////////////////////////////
          // 先关闭之前websocket, 避免之前有未关掉的连接
          try{
            // uni.closeSocket();
            self.websocket.close();
          } catch(err){}

          self.websocket = null;//全局WebSocket对象
          let lockReconnect = false; // 网络断开重连
          let wsCreateHandler = null; // 创建连接

          let userInfo=localStorage.getItem('userInfo')||"{}";
          userInfo=JSON.parse(userInfo);

          //1、创建
          createWebSocket()
          function createWebSocket(){
            try{
              if ('WebSocket' in window) {
                self.websocket = new WebSocket('wss://talk.xajjk.com/api/chat/getTalkWithStream?appid='+config.appId+'&openid='+userInfo.openid||'');
              } else if ('MozWebSocket' in window) {
                self.websocket = new MozWebSocket('wss://talk.xajjk.com/api/chat/getTalkWithStream?appid='+config.appId+'&openid='+userInfo.openid||'');
              } else {
                self.websocket = new SockJS('wss://talk.xajjk.com/api/chat/getTalkWithStream?appid='+config.appId+'&openid='+userInfo.openid||'');
              }
            }catch {
              reconnect();
              return;
            }
          
            //2、连接
            self.websocket.onopen = function(event) {
            // websocket.send("连接成功");
              console.log("服务已连接")
              // console.log("发送消息：",content.trim())
              self.websocket.send(content.trim())
            };
          
            //3、接收
            self.websocket.onmessage = function(res){
              let json=JSON.parse(res.data);

              self.isOver=true;
              self.webSocketNum++;
              // 如果第一次返回内容了
              if(self.webSocketNum==1){
                //去除loading, 增加内容
                self.talkList.splice(self.talkList.length-1,1,{
                  "pic":self.touxiang,
                  "content":'',
                  "time":new Date().getTime(),
                  "type":0,
                  'fromType':'share',
                  'questioner_id':userInfo.openid||'',
                  'isCurrent':true //用于显示光标
                });
                self.renderText();
              }

            
              // 如果返回结束
              if(json.msg.indexOf("[DONE]")>-1){
                //上传对话鉴黄并渲染
                renderRes({
                  q:content.trim(),
                  answer:self.text
                });
                //关闭webSocket
                self.websocket.close();
                self.webSocketNum=0;
                self.isOver=true;
              }else{
                self.text+=json.msg;
                delete self.talkList[self.talkList.length-1].hideToolBtn
                if(json.msgId.indexOf('cy-error')>-1){
                  self.websocket.close();
                  self.webSocketNum=0;
                }
              }
          
            };

            // 4、关闭
            self.websocket.onclose = function(event) {
              console.log("服务连接关闭")
            };

            // 5、报错
            self.websocket.onerror = function(res) {
              //如果已请求完毕，略过报错(ios会报isTrusted错误)
              if(self.isOver){
                return
              }
              console.log('WebSocket连接打开失败，请检查！',res);
              self.isFlag=false
              self.text=""
              delete self.talkList[self.talkList.length-1].isCurrent
              self.webSocketNum=0
              self.talkList.splice(self.talkList.length-1,1);
              self.talkList.push({
                pic:self.touxiang,
                "content":"您好，目前访问量太大，接口繁忙，请稍后再试。",
                "type":0
              })
              //关闭webSocket
              try{
                self.websocket.close();
              } catch(err){}

            };
          }
          /**
           *  异常处理
           *  处理可以检测到的异常，并尝试重新连接
           */
          function reconnect() {
            if (lockReconnect) {
              return;
            }
            console.log("reconnect");
            lockReconnect = true;
            // 没链接上会一直连接，设置延迟，避免过多请求
            wsCreateHandler && clearTimeout(wsCreateHandler);
            wsCreateHandler = setTimeout(function() {
              console.log("-----websoket异常-------");
              createWebSocket();
              lockReconnect = false;
            }, 1000);
          }
          //////////////////////////////////////////////////////////////////////////////////////////
          //发送数据请求-鉴黄并获得code
          function renderRes(requestData){
            request.setTalkInfo(requestData).then(res=>{
              console.log("上传对话接口返回：",res);
              self.isFlag=false;
              let time=new Date().getTime();
              //去除loading
              // self.talkList.splice(self.talkList.length-1,1);
              
              if(res.code<1){
                let data=res.data;
                //如果涉黄或出现问题
                if(!data.isSave){
                  clearInterval(self.textTimer);
                  self.text=""
                  self.webSocketNum=0
                  self.talkList[self.talkList.length-1].content=data.answer;
                }
                // 返回内容处理
                Object.assign(self.talkList[self.talkList.length-1],{
                  "time":data.time||time,
                  "code":data.isSave?data.code:'',
                  'fromType':'share'
                })
                self.$nextTick(()=>{
                  window.scrollTo(0, 999999)
                })
              }
              console.log("talkList:",self.talkList);

            }).catch(err=>{
              self.isFlag=false
              console.error(err);
            })

            self.$nextTick(()=>{
              // 清空内容框中的内容
              self.content = '';
              window.scrollTo(0, 999999)
            })
          }
          
          
          
        }
        
			},
       // 打字效果渲染
      renderText(){
        const self=this;
        let i=0;
        
        self.textTimer=setInterval(()=>{
          //判断并实现持续渲染
          if(i<self.text.length){
            self.talkList[self.talkList.length-1].content+=self.text[i]
            i++;
            if(i%15==0){
              window.scrollTo(0, 999999)
            }
          }else if(i>0&&self.webSocketNum<1){
            // 否则如果已经结束

            clearInterval(self.textTimer);
            self.text="";
            delete self.talkList[self.talkList.length-1].isCurrent
            self.textTimer=null;
            self.isFlag=null;
            
          }
        },100)
      },
      //提示弹框关闭
      showToastClose(){
        this.showToast.show=false;
      },
      //授权-已授权
      bindEmpower(userInfo){
        this.robot_avatar=userInfo.avatar;
      },
      //复制
      copyClick(text,hint){
        var textarea = document.createElement("textarea");
				var currentFocus = document.activeElement;
				document.body.appendChild(textarea);
				textarea.value = text;
				textarea.focus();
				if (textarea.setSelectionRange)
					textarea.setSelectionRange(0, textarea.value.length);
				else
					textarea.select();
				try {
					var flag = document.execCommand("copy");
				} catch (eo) {
					var flag = false;
				}
				document.body.removeChild(textarea);
				currentFocus.focus();
        
        this.showToast={
          show:true,
          title:hint||'复制成功~'
        }
				return flag;
      },
      //分享会话
      bindTalkShare(item,index){
        const self=this;
        let userInfo=localStorage.getItem('userInfo')||"{}";
        userInfo=JSON.parse(userInfo);

        let title=this.talkList[index-1].content.trim();

        //引导提示
        this.bindYindaoShare();

        this.talkShare={
          articleCode:item.code,
          // avatar:userInfo?userInfo.avatar:'',
          me:encodeURIComponent(title),
          // robot:item.content.trim(),
          from:'share',//用户动作来源
          fromType:item.fromType,  //主来源(初始来源)
          isUser:item.fromType.indexOf("quiz")>-1?0:1
        };
        
        this.shareImg=`https://xgapi.xajjk.com/api/shareCropImg?appid=${config.appId}&openId=${userInfo.openid||''}&me=${this.talkShare.me}`;
        console.log("分享图片：",this.shareImg);

        // if(userInfo&&userInfo.avatar){
          // this.talkShare.avatar=encodeURIComponent(userInfo.avatar);
        // }
        //原始提问者openid
        // if(item.questioner_id){
        //   this.talkShare.questioner_id=item.questioner_id;
        // }
        // if(item.time){
        //   this.talkShare.time=item.time;
        // }

        // 是否伪静态
        if(config.isStatic){
          // this.talkSharePath=config.host+'/talk/'+item.code+'.html?talkInfo='+JSON.stringify(this.talkShare);
          this.talkSharePath=config.host+'/talk/'+item.code+'.html';
        }else{
          this.talkSharePath=config.host+'/talk.html';
        }
        this.talkSharePath+='?articleCode='+item.code+'&isUser='+this.talkShare.isUser+'&from=share&fromType='+item.fromType+"&me="+this.talkShare.me
        if(item.questioner_id){
          this.talkSharePath+="&questioner_id="+item.questioner_id;
        }
        if(item.time){
          this.talkSharePath+="&time="+item.time;
        }


        //如果是微信浏览器
        if(util.isWechat()){
          try{
            let shareInfo=this.bindShareAppMessage();
            self.$wx.getConfig(title, "看看我们聊了什么", this.talkSharePath, shareInfo.imageUrl);
            console.log('分享对话-标题：',title);
          } catch(err){}
        }else{
          this.bindYindaoShareOut();
          //否则是其他浏览器
          this.copyClick("【看看我们聊了什么】点击链接查看 "+this.talkSharePath,'链接复制成功~');
        }
        console.log("分享对话：",this.talkSharePath);
      },

      ///////////////////// 分享 ////////////////////
      bindYindaoShare() {
        this.showYindaoShare = true;
      },
			bindYindaoShareOut() {
        const self=this;
        this.showYindaoShare = false;
        if(util.isWechat()){
          setTimeout(()=>{
            try{
              let shareInfo=this.bindShareAppMessage();
              self.$wx.getConfig(shareInfo.title, "越早打开越好", shareInfo.path, shareInfo.imageUrl);
              console.log('更改为默认分享数据-标题：',shareInfo.title);
            } catch(err){}
          },2000)
        }
      },
      bindShareAppMessage(){
        return {
          title: '欢迎使用'+config.appletName+'！',
          path: config.host+'/index.html',
          imageUrl:config.shareImg
        }
      },
      /////////////////////////////////////////////
      // 查看历史记录
      bindHistory(){
        location.href="history.html"
      },

    }

  }
</script>

<style lang="scss" scoped>
	@import "@/assets/css/global.scss";
  .cyContainer{
    background: #f3f3f3;
  }
  .navBar{
    width: 100%;
    box-sizing: border-box;
    padding: 0 15px;
    position: fixed;
    top: 0;
    z-index: 9995;
    background: #fff;
  }
  .navShareBtn {
    position: fixed;
    border-radius: 27px;
    line-height: 1;
    z-index: 99999;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 28px !important;
    border: 1px solid #1060f1;
  }
  .navbarTitle{
    position: absolute;
    font-size:30px;
  }
  .contentText{
    min-width:16%;    
    // min-height:0.2rem;  
    // font-size: 32px;  
    font-size: 0.3rem;  
    text-align: justify;
  }
	
	/* 加载数据提示 */
	.tips{
		position: fixed;
		left: 0;
		top:var(--window-top);
		width: 100%;
		z-index: 9;
		background-color: rgba(0,0,0,0.15);
		height: 72px;
		line-height: 72px;
		transform:translateY(-80px);
		transition: transform 0.3s ease-in-out 0s;
		
		&.show{
			transform:translateY(0);
		}
	}
	
	.box-1{
		width: 100%;
		height: auto;
		padding-bottom: 13vh;
		box-sizing: content-box;
		
		/* 兼容iPhoneX */
		margin-bottom: 0;  
		margin-bottom: constant(safe-area-inset-bottom);  
		margin-bottom: env(safe-area-inset-bottom);  
	}
	.box-2{
		position: absolute;
		left: 0;
		width: 100%;
		bottom: 0;
		// height: auto;
		z-index: 2;
		border-top: #e5e5e5 solid 1px;
		box-sizing: content-box;
		// background-color: #F3F3F3;
    box-shadow: -1px 0 8px #e4e4e4;
		
		/* 兼容iPhoneX */
		padding-bottom: 0;  
		padding-bottom: constant(safe-area-inset-bottom);  
		padding-bottom: env(safe-area-inset-bottom);  

		.content{
			background-color: #fff;
			height: 64px;
			padding: 0 20px;
			border-radius: 32px;
			font-size: 0.4rem;
		}
		
		.send{
			background-color: #42b983;
			color: #fff;
			// height: 64px;
			// line-height: 62px;
			height: 60px;
			line-height: 60px;
			margin-left: 20px;
			border-radius: 32px;
			padding: 0;
			width: 120px;
			
			&:active{
				background-color: #5fc496;
			}
		}
	}
	
	.talk-list{
    position: relative;
		padding-bottom: 15vh;
		
		/* 消息项，基础类 */
		.item{
			padding: 3vh 10px 0 10px;
			align-items:flex-start;
			align-content:flex-start;
			color: #333;
      // margin-top: 30px;
			
			.pic{
				width: 12%;
				height: auto;
				border-radius: 50%;
				border: #fff solid 1px;
        box-shadow: 0 0 5px #ccc;
			}
			
			.content{
				padding:10px 10px;
				border-radius: 4px;
				// max-width: 565px;
        max-width: 75%;
				word-break: break-all;
				line-height: 0.5rem;
				position: relative;
			}
			.quiz_item{
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #e7e7e7;
        padding: 6px;
        border-radius: 10px;
        cursor: pointer;
      }
      .quiz_item:last-child{
        border: none;
      }
      .quiz_icon{
        width:8%;
        height:auto
      }
      .quiz_item_hover{
        background: #f1f1f1;
      }
			/* 收到的消息 */
			&.pull{
				.content{
					margin-left: 4%;
          margin-top: 10px;
					background-color: #fff;
					
					&::after{
						content: '';
						display: block;
						width: 0;
						height: 0;
						border-top: 16px solid transparent;
						border-bottom: 16px solid transparent;
						border-right: 20px solid #fff;
						position: absolute;
						top: 10px;
						left: -10px;
					}
				}
			}
			
			/* 发出的消息 */
			&.push{
				/* 主轴为水平方向，起点在右端。使不修改DOM结构，也能改变元素排列顺序 */
				flex-direction: row-reverse;
				
				.content{
					margin-right: 4%;
					background-color: #a0e959;
					
					&::after{
						content: '';
						display: block;
						width: 0;
						height: 0;
						border-top: 16px solid transparent;
						border-bottom: 16px solid transparent;
						border-left: 20px solid #a0e959;
						position: absolute;
						top: 10px;
						right: -10px;
					}
				}
			}
		}
    .talkHandle{
      margin:15px 0 0 13%;
      display: flex;
      .talkShare,.talkCopy{
        display: inline-block;
        color: #6e6e6e;
        margin: 0 20px;
        padding: 0;
        margin: 0 20px;
        background: transparent;
      }
      .talkIcon{
        width: 15%;
        margin-right: 0px;
        display: inline-block;
        position: relative;
        // top:6px;
      }
      .btn{
        display: inline-block;
        padding: 0;
        font-size: 0.3rem;
        cursor: pointer;
      }
    }
	}

   /*闪烁动画*/
 @keyframes twinkle {
    0% {
      color: transparent;
    }
    60%{
      color:transparent;
    }
    80%{
      color:#000;
    }
    100% {
      color: #000;
    }
  }
  .flash {
    animation: twinkle 1s linear;
    animation-iteration-count: infinite;
  }

</style>