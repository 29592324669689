<template>
  <!-- 输入框 -->
  <div class="commentContainer">
  

  <div class="commentInput" :class="keyboardHeight?'transiton':''" :style="{paddingBottom:inputBottom+'px'}">
    <div class="footBtnBox">
      <div class="footBtn" @click="bindArticleList">
        <div style="display: flex;align-items: center;justify-content:center">
          <img class="footBtnIcon" src="@/assets/article.png" style="width:18%">
          热门文章
        </div>
      </div>
      <div class="footBtn" @click="bindQuizList">
        <div style="display: flex;align-items: center;justify-content:center">
          <img class="footBtnIcon" src="@/assets/quiz.png">
          精彩提问
        </div>
      </div>
      <div class="footBtn" @click="bindGroup">
        <div style="display: flex;align-items: center;justify-content:center">
          <img class="footBtnIcon" src="@/assets/wechat.png">
          微信群
        </div>
      </div>
      <!-- <div class="footBtn" @click="bindHistory">
        <div style="display: flex;align-items: center;justify-content:center">
          <img class="footBtnIcon" src="@/assets/history.png" >
          <span>历史</span>
        </div>
      </div> -->
      <div class="footBtn" @click="bindHome">
        <div style="display: flex;align-items: center;justify-content:center">
          <img class="footBtnIcon" src="@/assets/home.png" >
          <span>我的</span>
        </div>
      </div>
    </div>
    <div class="page-section">
      <div class="inputMain" @click="onFocus" style="padding-bottom:0">
       
        <!-- <div class="inputMainWrap" style="display:flex;align-items: flex-end;" :style="{marginBottom:inputBottom>1?'30px':'50px'}"> -->
        <div class="inputMainWrap">
          <!-- <div style="width:78%;">   -->
            <!-- <textarea
              v-model="commentVal"
              @blur="bindblur"
              @input="bindinput"
              @focus="bindfocus"
              @click="bindClick"
              @confirm="bindSubmit"
              :maxlength="1000"
              cursor-spacing='300'
              :adjust-position="false" 
              :show-confirm-bar="false" 
              :auto-height="true" 
              :focus="focus"
              :fixed="true"
              placeholder="请输入聊天内容"
              class="textarea"
              :disabled="isDisabled"  
              :disable-default-padding="true"
              @keydown.enter="bindSubmit_enter"
              >
            </textarea> -->
            <el-input
              style="width:78%;"
              class="textarea"
              type="textarea"
              :max="1000"
              :autosize="{ minRows: 1, maxRows: 4 }"
              :disabled="isDisabled"  
              placeholder="请输入聊天内容"
              resize='none'
              @blur="bindblur"
              @focus="bindfocus"
              @input="bindinput"
              v-model="commentVal"></el-input>


          <!-- </div> -->
          <!-- 获取焦点时显示的按钮 -->
          <div v-if="subBtnActive" class="subBtnpro subBtnActive">
            <!-- <button v-if="isGetPhone" open-type="getPhoneNumber" @getphonenumber="getPhoneNumber">发送</button> -->
            <button @click="bindSubmit">发送</button>
          </div>
          <div v-else class="subBtnpro">
            <button @click="bindSubmit">发送</button>
          </div>
        </div>
        <div style="display:flex;justify-content:center;padding:5px 0 15px 0">
          <a href="https://beian.miit.gov.cn/" target="_blank" style="text-decoration: none;color:#aaa;font-size:0.25rem;">备案号: 鲁ICP备15006419号-1</a>
        </div>
      </div>
    </div>
  </div>
 
</div>
</template>

<script>
import request from '../../../api/request';
import util from '../../../utils/utils';
import config from '../../../config';

import wxConfig from '../../../utils/wxConfig';
import userempower from '../userEmpower'
export default {
  props:{
    'getphone':{
      type:Boolean,
      default:false
    },
    'usernum':{
      type:Number,
      default:3
    },
    'disabled':{
      type:Boolean,
      default:false
    },
    'path':{
      type:String,
      default:''
    }
  },
  components:{
    userempower
  },
  data(){
    return {
      focus:false,
      commentVal:'',
      inputBottom: 0, //键盘高度
      subBtnActive: false, //发表按钮状态是否可以发送
      rouse:0, //获取键盘高度失败次数
      isGetPhone:this.getphone,
      isDisabled:this.disabled,
      userNum:this.usernum,
      currentPath:this.path,
      keyboardHeight:0 //键盘高度
    }
  },
  watch:{
    getphone:{
      handler(newVal,oldVal){
        this.isGetPhone=newVal;
      },
      deep:true,//深度监听
      immediate:false //
    },
    usernum:{
      handler(newVal,oldVal){
        this.userNum=newVal;
      },
      deep:true,//深度监听
      immediate:false //
    },
    disabled:{
      handler(newVal,oldVal){
        this.isDisabled=newVal;
      },
      deep:true,//深度监听
      immediate:false //
    },
    path:{
      handler(newVal,oldVal){
        this.currentPath=newVal;
      },
      deep:true,//深度监听
      immediate:false //
    },
  },
  mounted(){
    // wxConfig.setJMLink();
    document.documentElement.style.fontSize = document.documentElement.clientWidth / 750 * 100 + 'px';
  },
  methods:{

    //////////////////////////// 按钮跳转操作 ////////////////////////
    // 历史记录
    bindHistory(){
      this.testLogin(()=>{
        location.href="history.html";
      })
    },
    // 我的
    bindHome(){
      this.testLogin(()=>{
        location.href="home.html";
      })
    },
    //提问列表
    bindQuizList(){
      location.href="quizList.html";
    },
    //热门文章列表
    bindArticleList(){
      location.href="articleList.html";
    },
    // 进群
    bindGroup(){
      location.href="goGroup.html";
    },
    /////////////////////////////////////////////////////////////////
    //判断登录-去登陆
    testLogin(callBack,isSend){

      //是否是发送
      if(isSend){
        localStorage.setItem('sendChatContent',this.commentVal)
      }else{
        localStorage.removeItem('sendChatContent');
      }

      //是否不开启授权
      if(!config.isOpenEmpower){
        callBack();
        return
      }

      let userInfo=localStorage.getItem('userInfo')||"{}";
      userInfo=JSON.parse(userInfo);
      
      if(userInfo&&userInfo.openid){
        callBack();
      }else{  
        //授权
        // if(util.isWechat()){
          wxConfig.getSkipWxCode(this.currentPath);
        // }
      }
    },
    // 回车发送
    bindSubmit_enter(){
      // 如果不是移动端
      if(!util.isMobile()){
        this.bindSubmit();
      }
    },
    // 发送
    bindSubmit(){
      const self= this;

      if(!this.subBtnActive||this.commentVal.length<2){
        this.subBtnActive=false;
        // this.commentVal="";
        return;
      }
      this.testLogin(()=>{
        self.$emit('bindsubmit',this.commentVal)
      },true)

    },

    onFocus() {
      let self = this;
      if(this.isDisabled){
        this.$message({
          showClose: true,
          message: '正在处理中...请稍后！',
          duration:2000
        });
      }else{
        this.focus=true;
      }
    },
    // 输入框获取焦点时
    bindfocus(e) {
      // console.log('输入框获取焦点...', e.detail.value);
      // console.log('输入框获取焦点, 键盘高度...', e.detail.height);
      const self = this;
      let val = e.detail.value;
      self.blur = false; //是否失去焦点

      // 修复键盘高度为0的问题,避免无限递归5次判断
      if(e.detail.height==0){
        if(self.rouse<5){
          wx.hideKeyboard();
          self.focus=true;
          self.rouse++;
        }
      }
      self.keyboardHeight=e.detail.height
      self.inputBottom=e.detail.height
    },
    //输入框失去焦点时
    bindblur() {
      console.log('输入框失去焦点...', this.commentVal);
      const self = this;
      let value = this.commentVal.replace(/[\r\n]/g, "").replace(/\s*/g, "")
      if (value.length < 2 && self.subBtnActive) {
        self.subBtnActive = false
      } else if (value.length > 1 && !self.subBtnActive) {
        self.subBtnActive = true
      }else if(!value){
        self.commentVal="";
      }
      self.inputBottom = 0
      self.blur = true; //已失去焦点
      self.focus=false;
    },
    //输入框输入时
    bindinput(val) {
      const self = this;
      let value = val.replace(/[\r\n]/g, "").replace(/\s*/g, "")
      if (value.length < 2 && self.subBtnActive) {
        self.subBtnActive = false
      } else if (value.length > 1 && !self.subBtnActive) {
        self.subBtnActive = true
      }
    },
    
  }
}
</script>

<style lang="scss">
  .el-textarea__inner{
    min-height: 40px !important;
  }

  .commentContainer view,
  .commentContainer text,
  .commentContainer button {
    font-size: 30px;
    font-family: "Microsoft YaHei","SimHei";
  }
  .commentContainer{
    width: 100%;
    box-sizing: border-box;
    transition: all .1s linear;
  
    .commentInput{
      width: 100%;
      max-width: 420px;
      position: fixed;
      bottom: 0;
      box-sizing: border-box;
      background: #F1F1F1;
      border-top: 1px solid #EAEAEA;
      z-index: 4;
    }
    .transiton{
      transition: all .25s;
    }
    .page-section{
      width: 100%;
    }
    .subBtnpro button::after{
      border: none !important;
    }
    .subBtnpro{
      width: 20%;
      display: inline-block;
      height: auto;
    }
    .subBtnpro button{
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #909090;
      background: #E4E4E4;
      border-radius: 5px;
      margin: 0;
      font-size: 0.3rem;
      box-sizing: border-box;
      border: 0;
    }
    .subBtnActive button{
      background: #06C25F;
      color: #fff;
    }
    .inputMain{
      width: 100%;
      padding:5px 5px 5vh 5px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .inputMainWrap{
      width: 100%;
      box-sizing: border-box;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
    }
    .footBtnBox{
      height: auto;
      margin-top: 10px;
      margin-bottom: 5px;
      padding: 0 5px;
      display: flex;
    }
    .footBtn{
      display: inline-block;
      background: #fff;
      font-size: 0.3rem;
      color: #333333;
      border-radius: 9999px;
      box-shadow: 1px 0 5px #e4e4e4;
      padding: 3px;
      margin-left:5px;
      text-align: center;
      cursor: pointer;
    }
    .footBtn:first-child{
      margin-left:0;
    }
    .textarea{
      /* min-height:40px; */
      /* width: 100%;
      height:45px;
      max-height:180px;
      overflow-y:auto;
      padding: 5px;
      box-sizing: border-box; */
      /* min-height:40px;*/
      font-size: 0.32rem;
    }
    .footBtnIcon{
      width:20%;
      height:auto;
      display: inline-block;
      margin:0 5px 0 0;
      vertical-align:bottom
    }
  }

</style>